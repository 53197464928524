import { Analytics } from "@vercel/analytics/react"
import axios from "axios"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import { AppProps } from "next/app"
import { Router } from "next/router"
import nProgress from "nprogress"
import { SWRConfig } from "swr"
import "../styles/globals.css"
import "../styles/nprogress.css"

dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Seoul")

Router.events.on("routeChangeStart", () => nProgress.start())
Router.events.on("routeChangeComplete", () => nProgress.done())
Router.events.on("routeChangeError", () => nProgress.done())

export default function App({ Component, pageProps }: AppProps) {
  return (
    <SWRConfig
      value={{
        fetcher: (url) => axios.get(url).then((res) => res.data),
      }}
    >
      <div
        className="relative mx-auto h-full max-w-3xl text-black"
        style={{
          wordBreak: "keep-all",
        }}
      >
        <Component {...pageProps} />
        <Analytics
          beforeSend={(event) => {
            let { url } = event
            const invoiceNumber = url
              .split("/")
              .find((s) => s.match(/^[0-9\-]+$/)?.length)
            if (invoiceNumber) {
              url = url.replace(invoiceNumber, "[invoiceNumber]")
            }
            return {
              ...event,
              url,
            }
          }}
        />
      </div>
    </SWRConfig>
  )
}
